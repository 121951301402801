@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap");

/* body.login-body {
  font-family: "Mulish", sans-serif;
  color: #fff;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
} */

/* .main-container button.primary {
	cursor: pointer;
	padding: 0.65rem 1rem;
	background-color: #edc500;
  color: #222;
	border: none;
	font-weight: 600;
	border-radius: 10px;
	white-space: nowrap;
	font-family: 'Poppins';
	transition: all 100ms ease-in;
}

.main-container button.primary:hover {
	background-color: #161616;
	color: #edc500;
}

.main-container button.secondary {
	cursor: pointer;
	padding: 0.65rem 1rem;
	color: #edc500;
	background-color: transparent;
	border: none;
	font-weight: 600;
	border-radius: 10px;
	white-space: nowrap;
	font-family: 'Poppins';
	transition: all 100ms ease-in;
}

.main-container button.secondary:hover {
	background-color: #161616;
} */

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.outer-container {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none;
  background: linear-gradient(60deg, #000, #222, #000);
}
.outer-container::-webkit-scrollbar {
  display: none;
}

.overall-progress-text {
  margin-top: 0;
  margin-left: 30px;
  padding-top: 20px;
}

.progress-bar-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-wrap: nowrap;
  color: #f7df67;
}

.user-video-box {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 150px;
  height: 120px;
  border: 2px solid #fff;
  border-radius: 8px;
  flex: 9999;
}

.main-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.divide {
  width: 40%;
  height: 75vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.divide-right {
  width: fit-content;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loader-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: rgba(
    0,
    0,
    0,
    0.8
  ); /* Add a background color with some transparency */
  z-index: 10; /* Ensure it appears above other content */
}

.loader {
  border: 4px solid #edc500; /* Yellow */
  border-top: 4px solid #fff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 2s linear infinite;
}

.loader-message {
  margin-top: 20px;
  font-size: 18px;
  color: #fff;
}
.card-container {
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  width: clamp(400px, 30vw, 600px);
  min-width: 400px;
  padding: 0.75rem 1.25rem;
}

.text-answer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 20px auto;
}

.text-answer-container textarea {
  width: 80%;
  padding: 0.75rem 1.2rem;
  border-radius: 6px;
  border: 1px solid #333;
  background-color: transparent;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  resize: none;
}

.text-answer-container textarea:focus {
  outline: 1px solid #edc500;
}

.text-answer-container textarea::placeholder {
  color: #999;
}

.text-answer-container textarea:disabled {
  background-color: #333;
  color: #777;
}

.text-answer-container .timer-and-controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 80%;
  /* margin-top: px; */
}

.text-answer-container .controls button:disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
  opacity: 0.6;
}

.image-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-height: 80vh;
  padding-bottom: 30px;
}

.instruction-text {
  font-size: 20px;
  color: #fff;
  text-align: center;
  margin: 0;
  padding-bottom: 10px;
}

.question-image {
  max-width: 90%; /* Maximum width that image can occupy */
  max-height: 70vh; /* Limit the height to 50% of the viewport height */
  width: auto; /* Maintain aspect ratio */
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px;
  border: 2px solid #ccc;
  margin: 0 auto;
  padding: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
  background-color: #111;
  object-fit: contain; /* Ensure image scales without cropping */
}

.timer-and-controls {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.timer-and-controls .time-remaining {
  font-size: 12px;
  color: #666;
}
.timer {
  font-size: 20px;
  right: 0;
}

.recording-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  flex-direction: column;
  color: #edc500;
}
.recodingGif {
  margin-bottom: 20px;
  width: 150px;
}
.recording-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.recording-logo {
  margin-right: 8px;
  font-size: 20px;
  font-weight: bold;
  color: #f76565;
}
.recording-text {
  font-size: 20px;
  font-weight: bold;
  color: #ccc;
}
.wavefrom-external-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
}

.controls {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 10px 10px;
}

.waveform-external-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.waveform-container {
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  position: relative;
}

.waveform-timestamps {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 100%;
  margin-top: 10px;
  margin-right: 10px;
  color: #fff;
}

.waveform-timestamp {
  position: absolute;
  bottom: 0;
  font-size: 12px;
  white-space: nowrap;
  color: #fff;
}

.audio-player__controls {
  display: flex;
  padding-block: 0.75rem 1.25rem;
}

.controls__main {
  display: flex;
  align-items: center;
}

.controls__btn {
  display: flex;
  gap: 15px;
}

.main__btn {
  background-color: #1c1c1c;
  color: #edc500;
  padding: 0.75rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #333;
  transition: background-color 0.3s ease;
  font-size: 20px;
}

.main__btn:hover {
  background-color: #111;
  color: #edc500;
  border: 1px solid #edc500;
}

.controls__volume-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 10px;
}

.volume-icon {
  color: #edc500;
  padding: 0.75rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 18px;
}

.volume-icon:hover {
  background-color: #1c1c1c;
}

input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  border-radius: 16px;
}

.controls__volume-btn input[type="range"]::-webkit-slider-runnable-track {
  height: 8px;
  background: #555;
  border-radius: 16px;
}

.controls__volume-btn input[type="range"]::-moz-range-track {
  height: 8px;
  background: #555;
  border-radius: 16px;
}

.controls__volume-btn input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 8px;
  width: 8px;
  background-color: #fff;
  border-radius: 50%;
  border: #aa8800;
  box-shadow: -407px 0 0 400px #aa8800;
}

.controls__volume-btn input[type="range"]::-moz-range-thumb {
  height: 15px;
  width: 15px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #aa8800;
  box-shadow: -407px 0 0 400px #aa8800;
}

.logos {
  margin-right: 8px;
  font-size: 20px;
}

.controls .btn {
  padding: 10px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn.disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
  opacity: 0.6;
}

.btn-group {
  display: flex;
  gap: 10px;
}

.start_stop_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text-large-screen {
  display: inline;
}

.text-small-screen {
  display: none;
}

.audio-submit-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}
.submit-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: "25%";
  margin: auto;
}

.controls button.primary {
  display: flex;
  align-items: center;
}

.submit-btn.disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}

.submit-btn.disabled:hover {
  background-color: #bbb;
  color: #888;
  cursor: not-allowed;
}

.blinking-text {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.alertStyle {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  background-color: rgba(255, 0, 0, 0.7);
  color: #fff;
  border-radius: 5px;
  z-index: -1000;
}

@media (max-width: 768px) {
  .outer-container {
    height: 100%;
    min-height: 100vh;
    overflow-y: auto;
  }

  .main-container {
    flex-direction: column;
    gap: 0;
  }
  .user-video-box {
    display: none;
  }

  .progress-bar-container {
    margin: 0px 5px;
  }

  .divide {
    width: 100%;
    height: 65vh;
    padding: 0;
    align-items: center;
    justify-content: center;
  }

  .divide div iframe {
    display: flex;
    margin-top: 0;
    margin: 10px;
    padding: 0;
    margin-left: 30px;
    height: 100%;
    width: 100%;
  }

  .divide-right {
    width: 100%;
    margin-top: 10px;
    padding: 0;
    min-width: none;
    align-items: center;
    justify-content: flex-start;
  }

  .card-container {
    max-width: calc(100vw - 20%);
    min-width: 240px;
    margin-bottom: 30px;
    padding: 0.75rem 1rem;
  }

  .recording-container {
    margin-top: 0px;
  }

  .recording-container .recodingGif {
    display: none;
  }

  .recording-text {
    font-size: 14px;
  }

  .waveform-external-container {
    height: 50px;
    max-width: 400px;
    width: 100%;
    overflow-y: hidden;
    flex-direction: row-reverse;
  }

  .waveform-container {
    padding: 0px 10px;
  }

  .timer-and-controls {
    gap: 10px;
    /* margin-top: 5px; */
  }

  .timer {
    gap: 10px;
  }

  .controls {
    margin: 0px;
  }

  .controls .btn {
    padding: 5px;
    font-size: 14px;
    width: 100%;
  }

  .btn-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-right: 5px;
  }

  .audio-player__controls {
    align-items: flex-start;
  }

  .image-text-container {
    padding-bottom: 5px;
  }

  .instruction-text {
    margin: 0px 5px;
  }

  .text-answer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0px auto;
  }

  .text-answer-container textarea {
    width: 90%;
    font-size: 14px;
  }

  .text-answer-container .controls button {
    font-size: 14px;
    padding: 8px 16px;
    border-radius: 10px;
  }

  .greeting-container {
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
  }

  .waveform-timestamps {
    display: none;
  }

  .audio-player__controls {
    width: max-content;
    padding: 5px 10px;
  }

  .controls__btn {
    gap: 5px;
  }
  .hide {
    display: none;
  }
  .main__btn,
  .volume-icon {
    padding: 0.6rem;
    font-size: 16px;
  }

  .timer-and-controls .time-remaining {
    margin: 5px;
  }
  .question-timer {
    display: flex;
    flex-direction: column;
    width: 50%;
    min-width: 80px;
    align-items: center;
  }
  .start_stop_container {
    justify-content: center;
  }
  .start-stop-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }
  .logos {
    margin-right: 0px;
  }
  .start-stop-text-container {
    display: none;
    padding: 5px 10px;
  }
  .text-large-screen {
    display: none;
  }

  .text-small-screen {
    display: inline;
  }
  .submit-btn-container {
    width: 100%;
  }
  .recordingGif {
    display: none;
  }
  .overall-progress-text {
    display: none;
  }

  .submit-btn {
    font-size: 12px;
    padding: 6px;
    width: 45%;
  }

  .restart-btn {
    font-size: 12px;
    padding: 6px;
    width: 45%;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .main-container {
    flex-direction: row;
  }

  .greeting-container {
    flex-direction: row;
    align-items: flex-start;
    margin-top: 20px;
  }

  .submit-btn-container {
    width: 40%;
  }
}
